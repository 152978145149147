import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import PrefixStore from './prefix.module'
import MembersStore from './member.module'
import GamesStore from './games.module'

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
});

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		Prefix: PrefixStore,
		Members: MembersStore,
		Games: GamesStore,
	},
	plugins: [vuexLocal.plugin],
})