/* eslint-disable */
export default {
  arrayColumn: function (array, columnName) {
    // return array.map(function (value, index) {
    return array.map(function (value) {
      return value[columnName];
    });
  },
  numberWithCommas: function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  numberFormat: function (number, dot = 2, type = 0, locales = "th-TH", currency = "THB") {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
    // console.log(new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB', currencyDisplay: 'name' }).format(number));
    let options = null;
    switch (type) {
      case 1:
        options = { style: 'currency', currency: currency, currencyDisplay: 'name', minimumFractionDigits: dot, maximumFractionDigits: dot }
        break;

      case 2:
        options = { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: dot, maximumFractionDigits: dot }
        break;

      case 3:
        options = { style: 'currency', currency: currency, currencyDisplay: 'code', minimumFractionDigits: dot, maximumFractionDigits: dot }
        break;

      default:
        options = { minimumFractionDigits: dot, maximumFractionDigits: dot }
        break;
    }
    return new Intl.NumberFormat(locales, options).format(number)
  },
  arr_diff: function (a1, a2) {
    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  },
  formatDateTime: function (date) {
    return this.formatDate(date) + " " + this.formatTime(date)
  },
  formatDate: function (date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  },
  formatTime: function (datetime) {
    let d = new Date(datetime),
      H = '' + d.getHours(),
      I = '' + d.getMinutes(),
      S = '' + d.getSeconds();
    if (H.length < 2)
      H = '0' + H;

    if (I.length < 2)
      I = '0' + I;

    if (S.length < 2)
      S = '0' + S;

    return [H, I, S].join(':');
  },
  http_build_query: function (params) {
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
    return query;
  },
  datetimeThai: function (datetime, subUnit = 'น.') {
    return this.dateThai(datetime) + " เวลา " + this.timeThai(datetime, subUnit);
  },
  datetimeThaiHuayList: function (datetime, subUnit = 'น.') {
    return `${this.timeThai(datetime, subUnit)} ${this.dateThaiShort(datetime)}`;
  },
  datetimeEng: function (datetime, subUnit = 'min.') {
    return this.dateEng(datetime) + " " + this.timeEng(datetime, subUnit);
  },
  dateThai: function (datetime) {
    let monthNamesThai = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
      "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤษจิกายน", "ธันวาคม"];
    // let dayNames = ["วันอาทิตย์ที่", "วันจันทร์ที่", "วันอังคารที่", "วันพุทธที่", "วันพฤหัสบดีที่", "วันศุกร์ที่", "วันเสาร์ที่"];
    let d = new Date(datetime);
    // return dayNames[d.getDay()] + "  " + d.getDate() + " " + monthNamesThai[d.getMonth()] + "  " + (d.getFullYear() + 543);
    return d.getDate() + " " + monthNamesThai[d.getMonth()] + "  " + (d.getFullYear() + 543);
  },
  dateThaiShort: function (datetime) {
    let monthNamesThai = ["ม.ค.", "ก.พ.", "ม.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
      "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
    // let dayNames = ["วันอาทิตย์ที่", "วันจันทร์ที่", "วันอังคารที่", "วันพุทธที่", "วันพฤหัสบดีที่", "วันศุกร์ที่", "วันเสาร์ที่"];
    let d = new Date(datetime);
    // return dayNames[d.getDay()] + "  " + d.getDate() + " " + monthNamesThai[d.getMonth()] + "  " + (d.getFullYear() + 543);
    return d.getDate() + " " + monthNamesThai[d.getMonth()] + "  " + (d.getFullYear() + 543);
  },
  dateEng: function (datetime) {
    let monthNamesEng = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    // let dayNamesEng = ['Sunday', 'Monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    let d = new Date(datetime);
    // return dayNamesEng[d.getDay()] + "  " + d.getDate() + " " + monthNamesEng[d.getMonth()] + "  " + d.getFullYear();
    return d.getDate() + " " + monthNamesEng[d.getMonth()] + "  " + d.getFullYear();
  },
  timeThai: function (datetime, subUnit = 'น.') {
    let d = new Date(datetime),
      H = '' + d.getHours(),
      I = '' + d.getMinutes();

    if (H.length < 2)
      H = '0' + H;

    if (I.length < 2)
      I = '0' + I;

    return [H, I].join('.') + ' ' + subUnit;
  },
  timeEng: function (datetime, subUnit = 'min.') {
    let d = new Date(datetime),
      H = '' + d.getHours(),
      I = '' + d.getMinutes();

    if (H.length < 2)
      H = '0' + H;

    if (I.length < 2)
      I = '0' + I;

    return [H, I].join('.') + ' ' + subUnit;
  },
}
/*eslint-enable */
