<template>
  <div>
    <div class="container pt-5">
      <div class="e-login">
        <div class="e-login__body">
          <router-link to="/" class="e-header__logo">
            <!-- <img src="../assets/images/logo.svg" alt="logo euro wallet" /> -->
            <img :src="dataPrefix.logo" alt="logo euro wallet" />
          </router-link>

          <form @submit.prevent="submitForm">
            <fieldset class="form-group">
              <label>ธนาคาร</label>
              <div class="custom-select">
                <select
                  required="required"
                  ref="bank_code"
                  class="form-control form-control-lg"
                  v-model="form.bank_code"
                >
                  <option value="" selected="selected">
                    เลือกธนาคารของท่าน ...
                  </option>
                  <option value="KBANK">ธนาคารกสิกรไทย</option>
                  <option value="BBL">ธนาคารกรุงเทพ</option>
                  <option value="KTB">ธนาคารกรุงไทย</option>
                  <option value="BAY">ธนาคารกรุงศรีอยุธยา</option>
                  <option value="SCB">ธนาคารไทยพาณิชย์</option>
                  <option value="KK">ธนาคารเกียรตินาคิน</option>
                  <option value="CITI">ธนาคารซิตี้แบ้งค์</option>
                  <option value="CIMB">ธนาคารซีไอเอ็มบี (ไทย)</option>
                  <option value="TMB">ธนาคารทหารไทย</option>
                  <option value="TISCO">ธนาคารทิสโก้</option>
                  <option value="TBANK">ธนาคารธนชาต</option>
                  <option value="BAAC">ธ.ก.ส.</option>
                  <option value="UOBT">ธนาคารยูโอบี</option>
                  <option value="LH">ธนาคารแลนด์ แอนด์ เฮ้าส</option>
                  <option value="SC">ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)</option>
                  <option value="BOC">ธนาคารแห่งประเทศจีน (ไทย)</option>
                  <option value="BOFA">ธนาคารแห่งอเมริกาฯ</option>
                  <option value="GSB">ธนาคารออมสิน</option>
                  <option value="GH">ธนาคารอาคารสงเคราะห์</option>
                  <option value="IBANK">ธนาคารอิสลามแห่งประเทศไทย</option>
                  <option value="ICBC">ธนาคารไอซีบีซี (ไทย)</option>
                  <option value="HSBC">ธนาคารฮ่องกงและเซี่ยงไฮ้</option>
                </select>
                <i class="far fa-chevron-down"></i>
              </div>
            </fieldset>
            <fieldset class="form-group">
              <label>เลขบัญชี</label>
              <input
                type="text"
                class="form-control"
                required="required"
                inputmode="numeric"
                v-model="form.bank_account"
                placeholder="เลขบัญชี"
              />
            </fieldset>
            <fieldset class="form-group">
              <label>ชื่อจริง</label>
              <input
                type="text"
                class="form-control"
                required="required"
                v-model="form.first_name"
                placeholder="ชื่อจริง"
              />
              <small>* ไม่ต้องมีคำนำหน้าชื่อ</small>
            </fieldset>
            <fieldset class="form-group">
              <label>นามสกุล</label>
              <input
                type="text"
                class="form-control"
                required="required"
                v-model="form.last_name"
                placeholder="นามสกุล"
              />
            </fieldset>
            <button type="submit" class="e-btn--primary">ถัดไป</button>
            <span
              >คุณเป็นสมาชิกอยู่แล้ว ?
              <router-link to="/">เข้าสู่ระบบ</router-link></span
            >
          </form>
        </div>
      </div>
    </div>

    <vue-snotify class="snotify-custom"></vue-snotify>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["dataPrefix"],
  data() {
    return {
      prefix: "",
      form: {
        bank_code: "",
        bank_account: "",
        first_name: "",
        last_name: "",
      },
    };
  },
  methods: {
    async submitForm() {
      this.prefix = await localStorage.getItem("x-prefix");

      if (!this.prefix) {
        return;
      }

      let optionts = {
        headers: { prefix: this.prefix },
        form: this.form,
      };
      await this.$store.dispatch("Members/register_step2", optionts);
      if (this.$store.state.Members.statusCode === 200) {
        this.$router.push({
          path: "/register-3",
          query: { prefix: this.prefix },
        });
      } else {
        this.notifyError(this.$store.state.Members.responseError.message);
      }
    },
    notifyError(msg = "") {
      // add dark black ground
      document.body.classList.add("dark-overlay");
      // Failed case
      this.$snotify
        .error("ไม่สำเร็จ", msg, {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: false,
          buttons: [
            {
              text: "ปิดหน้าต่าง",
              action: null,
            },
          ],
        })
        .on("destroyed", () => {
          document.body.classList.remove("dark-overlay");
        });
    },
  },
  computed: {
    ...mapGetters("Members", ["getRegisterForm"]),
  },
  watch: {
    dataPrefix: function () {
      // console.log("dataPrefix:", this.dataPrefix);
    },
  },
  async mounted() {
    console.log("register2 page");
    this.prefix = await localStorage.getItem("x-prefix");
    if (this.getRegisterForm.formRegisterStep1 == null) {
      this.$router.push({
        path: "/register-1",
        query: { prefix: this.prefix },
      });
    }

    if (this.getRegisterForm.formRegisterStep2 != null) {
      this.form = this.getRegisterForm.formRegisterStep2;
    }
    // this.$refs.bank_code.focus();
  },
};
</script>