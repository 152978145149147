<template>
  <div>
    <section class="e-notice">
      <div class="container">
        <div class="e-notice__body">
          <i class="far fa-bullhorn"></i>
          <div class="e-notice__content">
            <p>
              ประกาศทดสอบระบบการทำงานทั้งหมดของเว็บไซต์ตั้งแต่เวลา 08.00 - 16.00
              น. ขออภัยในความไม่สะดวก
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Notice",
  components: {
    // HelloWorld,
  },
};
</script>
