import VueQrcode from '@chenfengyuan/vue-qrcode'
import moment from 'moment'
import 'moment/locale/th'
import Clipboard from 'v-clipboard'
import VueMask from 'v-mask'
//import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueMoment from 'vue-moment'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/simple.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import store from './store';
import CoreFunc from './coreFunc';

// import './assets/css/main.css';

Vue.config.productionTip = false

Vue.use(Snotify, {
	toast: {
		position: SnotifyPosition.centerCenter,
		titleMaxLength: 256,
		// maxOnScreen: 1,
		// oneAtTime: true,
		// preventDuplicates: true,
	},
})

Vue.use(Clipboard)

Vue.use(VueMoment, {
	moment,
})

Vue.use(VueMask)
/*
Vue.use(Loading, {
	width: 100,
	height: 100,
	color: '#ffc800',
	backgroundColor: '#000',
})*/

Vue.component(VueQrcode.name, VueQrcode)

Vue.prototype.$URL_API_V1 = "https://wallet-api.euro289.biz/v1";
Vue.prototype.$coreFunc = CoreFunc;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
