<template>
  <div>
    <Header :headerClass="true" menuName="แนะนำเพื่อน" />
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>แนะนำเพื่อน</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <h1 class="e-wrapper__title">แนะนำเพื่อน</h1>

        <!-- Label Noti -->
        <div class="e-label e-label--warning">
          <i class="far fa-exclamation-square"></i>
          <span
            >ผู้ถูกแนะนำจะต้องรับ
            <strong>"โบนัสสมัครสมาชิกใหม่"</strong> อัตโนมัติ เท่านั้น</span
          >
        </div>

        <div class="e-affiliate">
          <div class="e-affiliate__info">
            <img src="#" alt="../../assets/images/qrcode.png" />
            <div class="e-text-view">
              <span>รหัสชวนเพื่อน</span>
              <p>PGTH45708</p>
            </div>
            <div class="e-text-view">
              <span>ลิงก์ชวนเพื่อน</span>
              <a
                href="https://euro-wallet.com/register?prefix=PGTH&ref=PGTH45708"
                >https://euro-wallet.com/register?prefix=PGTH&ref=PGTH45708</a
              >
              <div class="e-btn--copy">
                <i class="far fa-clone"></i>
                <span>คัดลอก</span>
              </div>
            </div>
            <div class="e-btn--primary" v-on:click="toggleModal()">
              โยกเงินเข้ากระเป๋าหลัก
            </div>
          </div>

          <div class="e-affiliate__history">
            <div class="e-affiliate__history--title">
              <p>
                <strong>รายการชวนเพื่อน</strong> <span>(ทั้งหมด 5 คน)</span>
              </p>
              <a href="./history.html">ดูประวัติธุรกรรม</a>
            </div>
            <div class="e-affiliate__history--list">
              <div class="e-history e-history--sm">
                <div class="e-history__content">
                  <h1>สมหญิง</h1>
                  <!-- ใช้แค่ชื่อจริง -->
                  <p>088-888-8888</p>
                </div>
                <div class="e-history__content">
                  <h1>ฝากเงินแล้ว</h1>
                  <p>24/05/2020 07:14:40</p>
                </div>
                <!--
                                <div class="e-history__remark">
                                    <small>* ทดสอบการใส่หมายเหตุ</small>
                                </div>
                            -->
              </div>

              <div class="e-history e-history--sm">
                <div class="e-history__content">
                  <h1>สมหมาย</h1>
                  <p>088-888-8888</p>
                </div>
                <div class="e-history__content">
                  <h1 class="in-active">ยังไม่ฝากเงิน</h1>
                  <p>24/05/2020 07:14:40</p>
                </div>
              </div>

              <div class="e-history e-history--sm">
                <div class="e-history__content">
                  <h1>ประสิทธิ์</h1>
                  <p>088-888-8888</p>
                </div>
                <div class="e-history__content">
                  <h1>ฝากเงินแล้ว</h1>
                  <p>24/05/2020 07:14:40</p>
                </div>
              </div>

              <div class="e-history e-history--sm">
                <div class="e-history__content">
                  <h1>ทันสมัย</h1>
                  <p>088-888-8888</p>
                </div>
                <div class="e-history__content">
                  <h1>ฝากเงินแล้ว</h1>
                  <p>24/05/2020 07:14:40</p>
                </div>
              </div>

              <div class="e-history e-history--sm">
                <div class="e-history__content">
                  <h1>รัตนา</h1>
                  <p>088-888-8888</p>
                </div>
                <div class="e-history__content">
                  <h1 class="in-active">ยังไม่ฝากเงิน</h1>
                  <p>24/05/2020 07:14:40</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="e-modal">
      <div class="e-modal__body">
        <div class="e-modal__title">
          <h1>โยกเงิน</h1>
        </div>

        <div class="e-transfer">
          <div class="e-transfer__head">
            <div class="e-transfer__head--info">
              <div class="e-icon-transfer e-icon-transfer--from">
                <i class="fas fa-users"></i>
              </div>
              <div class="js-wallet-info">
                <span>แนะนำเพื่อน</span>
                <h1>฿ 100.00</h1>
              </div>
            </div>
            <div class="e-transfer__head--info">
              <div class="e-icon-transfer e-icon-transfer--to">
                <i class="fas fa-wallet"></i>
              </div>
              <div class="js-wallet-info">
                <span>กระเป๋าหลัก</span>
                <h1>฿ 7,135.40</h1>
              </div>
            </div>
            <i class="far fa-arrow-right"></i>
          </div>
          <form action="./affiliate.html">
            <fieldset class="form-group">
              <label>จำนวนเงินที่ต้องการโยก (บาท)</label>
              <input
                type="number"
                class="form-control"
                required="required"
                placeholder="0"
              />
              <small>* โยกเงินขั้นต่ำ 300 บาท</small>
            </fieldset>
            <button type="submit" class="e-btn--primary">โยกเงิน</button>
            <div class="e-btn--secondary" v-on:click="toggleModal()">
              ยกเลิก
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />
    <Tabs />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";

export default {
  name: "Affiliate",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  methods: {
    toggleModal() {
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
  },
};
</script>
