<template>
  <div>
    <Header :headerClass="true" menuName="โปรไฟล์" />
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>โปรไฟล์</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <h1 class="e-wrapper__title e-wrapper__title--center">โปรไฟล์</h1>

        <div class="e-account">
          <div class="e-account__info">
            <h3>ข้อมูลส่วนตัว</h3>
            <div class="e-account__info--text">
              ชื่อ-นามสกุล :
              <strong
                >{{ getProfile.first_name }} {{ getProfile.last_name }}</strong
              >
            </div>
            <div class="e-account__info--text">
              เบอร์โทรศัพท์ : <strong>{{ getProfile.phone }}</strong>
            </div>
          </div>

          <div class="e-account__info">
            <h3>บัญชีผู้ใช้งาน</h3>
            <div class="e-account__info--text">
              รหัสผู้ใช้งาน : <strong>euro37at619188</strong>
              <div class="e-btn--copy">
                <i class="far fa-clone"></i>
                <span>คัดลอก</span>
              </div>
            </div>
            <div class="e-account__info--text">
              รหัสผ่าน : <strong>Wew12345678</strong>
              <div class="e-btn--copy">
                <i class="far fa-clone"></i>
                <span>คัดลอก</span>
              </div>
            </div>
            <div class="e-btn--primary" v-on:click="toggleModal()">
              เปลี่ยนรหัสผ่าน
            </div>
          </div>

          <div class="e-bank">
            <div class="e-bank__logo" data-logo="scb"></div>
            <div class="e-bank__info">
              <div class="e-bank__info--text">
                <small>{{ getProfile.bank_code }}</small>
                <h2>{{ getProfile.bank_account }}</h2>
                <span
                  >{{ getProfile.first_name }} {{ getProfile.last_name }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="e-modal">
      <div class="e-modal__body">
        <div class="e-modal__title">
          <h1>เปลี่ยนรหัสผ่าน</h1>
        </div>

        <div class="e-transfer">
          <form action="./account.html">
            <fieldset class="form-group">
              <label>รหัสผ่านปัจจุบัน</label>
              <input
                type="text"
                class="form-control"
                required="required"
                placeholder="รหัสผ่านปัจจุบัน"
              />
            </fieldset>
            <fieldset class="form-group">
              <label>รหัสผ่านใหม่</label>
              <input
                type="text"
                class="form-control"
                required="required"
                placeholder="รหัสผ่านใหม่"
              />
            </fieldset>
            <fieldset class="form-group">
              <label>ยืนยันรหัสผ่านใหม่</label>
              <input
                type="text"
                class="form-control"
                required="required"
                placeholder="ยืนยันรหัสผ่านใหม่"
              />
            </fieldset>
            <button type="submit" class="e-btn--primary">
              เปลี่ยนรหัสผ่าน
            </button>
            <div class="e-btn--secondary" v-on:click="toggleModal()">
              ยกเลิก
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />
    <Tabs />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  data() {
    return {};
  },
  methods: {
    toggleModal() {
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
  },
  computed: {
    ...mapGetters("Members", ["getProfile"]),
  },
  watch: {
    getProfile: function () {
      // console.log("watch getProfile",this.getProfile);
    },
  },
  async mounted() {
    console.log("account page");
    // console.log("mounted getProfile",this.getProfile);
  },
};
</script>
