<template>
  <div>
    <div class="container">
      <div class="full-height">
        <div class="e-login">
          <div class="e-login__body">
            <router-link to="/" class="e-header__logo">
              <!-- <img src="../assets/images/logo.svg" alt="logo euro wallet" /> -->
              <img :src="dataPrefix.logo" alt="logo euro wallet" />
            </router-link>

            <form @submit.prevent="submitForm">
              <fieldset class="form-group">
                <label>เบอร์โทรศัพท์</label>
                <input
                  type="tel"
                  class="form-control"
                  required="required"
                  ref="phone"
                  inputmode="tel"
                  minlength="10"
                  placeholder="เบอร์โทรศัพท์"
                  v-model="form.phone"
                />
              </fieldset>
              <button type="submit" class="e-btn--primary">ถัดไป</button>
              <span>
                คุณเป็นสมาชิกอยู่แล้ว ?
                <router-link to="/">เข้าสู่ระบบ</router-link>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>

    <vue-snotify class="snotify-custom"></vue-snotify>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["dataPrefix"],
  data() {
    return {
      prefix: "",
      form: {
        phone: "",
      },
    };
  },
  methods: {
    async submitForm() {
      this.prefix = await localStorage.getItem("x-prefix");

      if (!this.prefix) {
        return;
      }

      let optionts = {
        headers: { prefix: this.prefix },
        form: this.form,
      };
      await this.$store.dispatch("Members/register_step1", optionts);
      if (this.$store.state.Members.statusCode === 200) {
        this.$router.push({
          path: "/register-2",
          query: { prefix: this.prefix },
        });
      } else {
        this.notifyError(this.$store.state.Members.responseError.message);
      }
    },
    notifyError(msg = "") {
      // add dark black ground
      document.body.classList.add("dark-overlay");
      // Failed case
      this.$snotify
        .error("ไม่สำเร็จ", msg, {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: false,
          buttons: [
            {
              text: "ปิดหน้าต่าง",
              action: null,
            },
          ],
        })
        .on("destroyed", () => {
          document.body.classList.remove("dark-overlay");
        });
    },
  },
  computed: {
    ...mapGetters("Members", ["getRegisterForm"]),
  },
  watch: {
    dataPrefix: function () {
      // console.log("dataPrefix:", this.dataPrefix);
    },
  },
  async mounted() {
    console.log("register1 page");
    if (this.getRegisterForm.formRegisterStep1 != null) {
      this.form = this.getRegisterForm.formRegisterStep1;
    }
    this.prefix = await localStorage.getItem("x-prefix");
    this.$refs.phone.focus();
  },
};
</script>