<template>
  <div>
    <Header :headerClass="true" menuName="กงล้อเสี่ยงโชค" />
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>กงล้อเสี่ยงโชค</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <div class="lucky-wheel">
          <div class="lucky-wheel__frame">
            <img
              src="../../assets/images/lucky-wheel/wheel_back.png"
              class="animate-form"
            />
            <img
              src="../../assets/images/lucky-wheel/wheel_back_2.png"
              class="animate-to"
            />
          </div>

          <div class="lucky-wheel__wheel">
            <canvas
              id="canvas"
              width="534"
              height="534"
              data-responsiveMinWidth="10"
              data-responsiveScaleHeight="true"
              data-responsiveMargin="0"
            >
              <p style="color: white" align="center">
                Sorry, your browser doesn't support canvas. Please try another.
              </p>
            </canvas>
          </div>

          <div class="lucky-wheel__arrow">
            <img src="../../assets/images/lucky-wheel/arrow.png" />
          </div>

          <div class="lucky-wheel__play">
            <img
              id="spin_button"
              src="../../assets/images/lucky-wheel/spin_on.png"
              alt="Spin"
              onClick="startSpin();"
            />
          </div>
        </div>
        <div class="num-spin">
          <p>คุณมีสิทธิ์หมุน <span id="numSpin"></span> ครั้ง</p>
        </div>

        <!-- Reward Modal -->
        <div id="reward" class="e-modal">
          <div class="e-modal__body">
            <div class="e-reward">
              <p>ยินดีด้วยคุณได้รับ</p>
              <h1 id="rewardToPlay"></h1>
              <div
                class="e-btn--primary"
                onclick="document.getElementById('reward').style.display = 'none';"
              >
                รับรางวัล
              </div>
            </div>
          </div>
        </div>

        <div class="e-reward--history">
          <h2>รายการรับรางวัลล่าสุด (10 รายการ)</h2>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>ทอง 1 บาท</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 5.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>ทอง 1 บาท</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 5.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 5.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>ทอง 1 บาท</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 5.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>ทอง 1 บาท</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 5.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>คุณฉัตรเฉลิม</h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 5.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
    
    <Tabs />

  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";

export default {
  name: "LuckySpin",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  methods: {
    toggleModal() {
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
  },
};
</script>