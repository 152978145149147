<template>
  <div>
    <Header :headerClass="true" menuName="โยกเงิน" />
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>โยกเงิน</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <h1 class="e-wrapper__title mb-0">โยกเงิน</h1>
        <p class="e-wrapper__description">
          กรุณาเลือกบัญชีเกมที่ต้องการโยกเงิน
        </p>

        <div class="e-games e-games--mt-sm">
          <div class="e-games__row">
            <!-- แนะนำให้แสดงเฉพาะค่ายเกมที่ Active เท่านั้น -->
            <div class="e-game" v-for="(game, index) in listGames" :key="index">
              <div class="e-game__logo">
                <img :src="listLogo[game.key]" alt="" />
              </div>
              <div class="e-game__info">
                <div class="e-game__info--text">
                  <h2>{{ game.key }}</h2>
                  <span>{{ game.balance }}</span>
                </div>
                <div class="e-game__info--btn">
                  <div class="e-btn--info" v-on:click="toggleModal(game)">
                    <i class="far fa-exchange"></i>
                    <span>โยกเงิน</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="e-modal">
      <div class="e-modal__body">
        <div class="e-modal__title">
          <h1>โยกเงิน</h1>
        </div>

        <div class="e-transfer">
          <div class="e-transfer__head">
            <div class="e-transfer__head--info">
              <div class="e-icon-transfer e-icon-transfer--from">
                <i class="fas fa-wallet js-switch-icon"></i>
              </div>
              <div class="js-wallet-info">
                <span>กระเป๋าหลัก</span>
                <h1>{{ "฿ " + $coreFunc.numberFormat(getProfile.money) }}</h1>
              </div>
            </div>
            <div class="e-transfer__head--info">
              <div class="e-icon-transfer e-icon-transfer--to">
                <img
                  class="js-switch-icon"
                  :src="listLogo[dataModal.key]"
                />
              </div>
              <div class="js-wallet-info">
                <span>{{ dataModal.key }}</span>
                <h1>{{ dataModal.balance }}</h1>
              </div>
            </div>
            <i class="far fa-arrow-right"></i>
          </div>
          <div id="tabMenu" class="e-tab">
            <div
              class="e-tab__link e-tab__link--active"
              v-on:click="switchWallet($event)"
            >
              โยกเข้าเกม
            </div>
            <div class="e-tab__link" v-on:click="switchWallet($event)">
              โยกเข้ากระเป๋า
            </div>
          </div>
          <form action="./transfer.html">
            <fieldset class="form-group">
              <label>จำนวนเงินที่ต้องการโยก (บาท)</label>
              <input
                type="text"
                class="form-control"
                required="required"
                :min="dataModal.MinWithdraw"
                :max="dataModal.MaxWithdraw"
                inputmode="decimal"
                placeholder="0"
              />
              <small>* โยกเงินขั้นต่ำ {{ dataModal.MinWithdraw }} บาท และโยกเงินสูงสุด {{ dataModal.MaxWithdraw }} บาท</small>
            </fieldset>
            <fieldset class="form-group">
              <label>เลือกโบนัสที่ต้องการรับ</label>
              <div class="custom-select">
                <select
                  required="required"
                  class="form-control form-control-lg"
                >
                  <option value="off" selected="selected">
                    ไม่รับโบนัสอัตโนมัติ
                  </option>
                  <option value="newuser">สมัครสมาชิกใหม่ 50%</option>
                  <option value="pgth8214">
                    BONUS100% ฝาก 50 รับ 100!! (เฉพาะสมาชิกใหม่)
                  </option>
                  <option value="daily_20">ฝากแรกของวัน 20%</option>
                  <option value="10percent">ฝากทั้งวัน 10%</option>
                </select>
                <i class="far fa-chevron-down"></i>
              </div>
            </fieldset>
            <button type="submit" class="e-btn--primary">โยกเงิน</button>
            <div class="e-btn--secondary" v-on:click="toggleModal()">
              ยกเลิก
            </div>
          </form>
        </div>
      </div>
    </div>
    <Footer />
    <Tabs />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";
import { mapGetters } from "vuex";

export default {
  name: "Transfer",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  data() {
    return {
      prefix: "",
      listGames: [],
      dataModal: {},
      listLogo: {
        Gclub: require("../../assets/images/slot-logo/ambpoker.png"),
        Live22: require("../../assets/images/slot-logo/live22.png"),
        Ambpoker: require("../../assets/images/slot-logo/ambpoker.png"),
        Dragoonsoft: require("../../assets/images/slot-logo/dragoonsoft.png"),
        Pgslot: require("../../assets/images/slot-logo/pgslot.png"),
        Gamatron: require("../../assets/images/slot-logo/gamatron.png"),
        Slotxo: require("../../assets/images/slot-logo/slotxo.png"),
        Ameba: require("../../assets/images/slot-logo/ameba.png"),
        Spadegaming: require("../../assets/images/slot-logo/spadegaming.png"),
      },
    };
  },
  methods: {
    toggleModal(game = null) {
      if (game) {
        this.dataModal = game;
        // console.log("dataModal", this.dataModal);
      } else {
        this.dataModal = {};
      }

      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
    switchWallet(e) {
      const walletInfo = document.getElementsByClassName("js-wallet-info");
      const icon = document.getElementsByClassName("js-switch-icon");
      const tabs = document.getElementsByClassName("e-tab__link");
      let btn = e.target;

      if (
        btn.className === "e-tab__link" &&
        walletInfo.length === 2 &&
        icon.length === 2
      ) {
        this.swapElements(walletInfo[0], walletInfo[1]);
        this.swapElements(icon[0], icon[1]);

        for (let i = 0; i < tabs.length; i++) {
          tabs[i].classList.remove("e-tab__link--active");
        }

        btn.classList.add("e-tab__link--active");
      }
    },
    swapElements(obj1, obj2) {
      let temp = document.createElement("div");
      obj1.parentNode.insertBefore(temp, obj1);
      obj2.parentNode.insertBefore(obj1, obj2);
      temp.parentNode.insertBefore(obj2, temp);
      temp.parentNode.removeChild(temp);
    },
    async getBalance(provider, key = "") {
      let token = localStorage.getItem("token");
      if (!token) {
        await localStorage.removeItem("token");
        return 0;
      }
      let optionts = {
        headers: { prefix: this.prefix, token: token },
        form: { provider: provider },
      };
      await this.$store.dispatch("Games/balance", optionts);
      if (this.$store.state.Games.statusCode === 200) {
        const respBalance = await this.$store.state.Games.respBalance;
        let element = this.getProfile.game[provider];
        element["key"] = key;
        element["balance"] =
          "฿ " + this.$coreFunc.numberFormat(respBalance.balance);
        this.listGames.push(element);
        return respBalance.balance;
      } else {
        if (!Object.hasOwnProperty.call(this.getProfile.game, key)) {
          return 0;
        }
        let element = this.getProfile.game[provider];
        element["key"] = key;
        element["balance"] = "ยังไม่ได้สมัคร";
        this.listGames.push(element);
      }
      return 0;
    },
    async init() {
      this.listGames = [];
      for (const key in this.getProfile.game) {
        if (Object.hasOwnProperty.call(this.getProfile.game, key)) {
          const element = this.getProfile.game[key];
          await this.getBalance(element.Provider, key);
        }
      }
    },
  },
  computed: {
    // ...mapState('Members', ['respMe']),
    ...mapGetters("Prefix", ["getPrefix"]),
    ...mapGetters("Members", ["getProfile"]),
    // ...mapState({ respMe: "Members/respMe" }),
  },
  async mounted() {
    console.log("transfer page");
    this.prefix = await localStorage.getItem("x-prefix");

    await this.init();
  },
};
</script>
