<template>
  <div>
    <div class="container">
      <div class="full-height">
        <div class="e-login">
          <div class="e-login__body">
            <router-link to="/" class="e-header__logo">
              <!-- <img src="../assets/images/logo.svg" alt="logo euro wallet" /> -->
              <img :src="dataPrefix.logo" alt="logo euro wallet" />
            </router-link>

            <div class="e-login__success">
              <i class="fal fa-check-circle"></i>
              <h2>สมัครสมาชิกเรียบร้อยแล้ว</h2>
              <small
                >คุณสามารถใช้เบอร์โทรศัพท์และรหัสผ่านด้านล่างเพื่อเข้าสู่ระบบ</small
              >

              <div class="e-login__success--info">
                <div>
                  เบอร์โทรศัพท์ : <strong>{{ phone }}</strong>
                </div>
                <div>
                  รหัสผ่าน : <strong>{{ password }}</strong>
                </div>
              </div>

              <router-link to="/menus/dashboard" class="e-btn--primary"
                >เข้าสู่หน้าหลัก</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  components: {},
  props: ["dataPrefix"],
  data() {
    return {
      prefix: "",
      phone: "",
      password: "",
    };
  },
  methods: {
    async is_logged_in() {
      let token = await this.respRegisterStep3.token;

      if (!token) {
        return false;
      }

      let optionts = {
        headers: { prefix: this.prefix, token: token },
      };
      await this.$store.dispatch("Members/me", optionts);
      if (this.$store.state.Members.statusCode === 200) {
        this.$store.dispatch("Members/resetFormRegister");
        await localStorage.setItem("token", token);
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapState("Members", ["respRegisterStep3"]),
    ...mapGetters("Members", ["getRegisterForm"]),
  },
  watch: {
    dataPrefix: function () {
      // console.log("dataPrefix:", this.dataPrefix);
    },
  },
  async mounted() {
    console.log("register4 page");
    this.prefix = await localStorage.getItem("x-prefix");
    if (this.getRegisterForm.formRegisterStep3 == null) {
      this.$router.push({
        path: "/register-1",
        query: { prefix: this.prefix },
      });
    }
    this.phone = this.getRegisterForm.formRegisterStep3.phone;
    this.password = this.getRegisterForm.formRegisterStep3.password;
    if (!(await this.is_logged_in())) {
      this.$router.push({
        path: "/register-1",
        query: { prefix: this.prefix },
      });
    }
  },
};
</script>