<template>
  <div class="container py-5">
    <div class="e-login">
      <div class="e-login__body">
        <router-link to="/" class="e-header__logo">
          <!-- <img src="../assets/images/logo.svg" alt="logo euro wallet" /> -->
          <img :src="dataPrefix.logo" alt="logo euro wallet" />
        </router-link>

        <form @submit.prevent="submitForm">
          <fieldset class="form-group">
            <label>รหัสผ่าน</label>
            <input
              type="text"
              ref="password"
              class="form-control"
              required="required"
              placeholder="รหัสผ่าน"
              minlength="8"
              v-model="form.password"
            />
            <small
              >* รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร</small
            >
          </fieldset>
          <fieldset class="form-group">
            <label>ไอดีไลน์</label>
            <input
              type="text"
              class="form-control"
              required="required"
              placeholder="ไอดีไลน์"
              v-model="form.line_id"
            />
          </fieldset>
          <!-- <fieldset class="form-group">
            <label>รับโบนัสอัตโนมัติ</label>
            <div class="custom-select">
              <select
                required="required"
                class="form-control form-control-lg"
                v-model="form.bonus"
              >
                <option value="off" selected="selected">
                  ไม่รับโบนัสอัตโนมัติ
                </option>
                <option value="newuser">สมัครสมาชิกใหม่ 50%</option>
                <option value="pgth8214">
                  BONUS100% ฝาก 50 รับ 100!! (เฉพาะสมาชิกใหม่)
                </option>
                <option value="daily_20">ฝากแรกของวัน 20%</option>
                <option value="10percent">ฝากทั้งวัน 10%</option>
              </select>
              <i class="far fa-chevron-down"></i>
            </div>
          </fieldset> -->
          <fieldset class="form-group">
            <label>ช่องทางที่รู้จักเรา</label>
            <div class="custom-select">
              <select
                required="required"
                class="form-control form-control-lg"
                @change="selectKnowUs($event)"
                v-model="form.know_from"
              >
                <option value="" selected="selected">
                  เลือกช่องทางที่รู้จักเรา ...
                </option>
                <option value="facebook">Facebook</option>
                <option value="line">Line</option>
                <option value="google">Google</option>
                <option value="instargram">Instargram</option>
                <option value="youtube">Youtube</option>
                <option value="sms">SMS</option>
                <option value="friend">เพื่อนแนะนำ</option>
                <option value="other">โฆษณาอื่น ๆ</option>
              </select>
              <i class="far fa-chevron-down"></i>
            </div>
          </fieldset>
          <fieldset id="affiliateID" class="form-group" style="display: none">
            <label>รหัสชวนเพื่อน</label>
            <input
              type="text"
              class="form-control"
              :required="isRequiredAffiliateID"
              placeholder="รหัสชวนเพื่อน"
              v-model="form.affiliate"
            />
            <small>* ไม่จำเป็น</small>
          </fieldset>
          <fieldset class="form-group">
            <label>กรอกรหัสตามภาพ</label>
            <img :src="imgCaptcha" alt="image captcha" />
            <input
              type="text"
              class="form-control"
              required="required"
              placeholder="กรอกรหัสตามภาพ"
              v-model="form.captcha"
            />
          </fieldset>
          <button type="submit" class="e-btn--primary">สมัครสมาชิก</button>
          <span
            >คุณเป็นสมาชิกอยู่แล้ว ?
            <router-link to="/">เข้าสู่ระบบ</router-link></span
          >
        </form>
      </div>
    </div>

    <vue-snotify class="snotify-custom"></vue-snotify>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["dataPrefix"],
  data() {
    return {
      prefix: "",
      imgCaptcha: "",
      isRequiredAffiliateID: false,
      form: {
        password: "",
        line_id: "",
        // bonus: "",
        know_from: "",
        affiliate: "",
        captcha: "",
      },
    };
  },
  methods: {
    selectKnowUs(e) {
      const element = document.getElementById("affiliateID");
      let value = e.target.value;
      if (value === "friend") {
        element.style.display = "block";
        this.isRequiredAffiliateID = true;
      } else {
        element.style.display = "none";
        this.isRequiredAffiliateID = false;
      }
      this.form.affiliate = "";

      // console.log(e.target.value);
    },
    async callRegisterCaptchaImage() {
      this.prefix = await localStorage.getItem("x-prefix");

      if (!this.prefix) {
        return;
      }

      let optionts = {
        headers: { prefix: this.prefix },
        form: this.form,
      };
      await this.$store.dispatch("Members/register_captcha", optionts);
      if (this.$store.state.Members.statusCode === 200) {
        this.imgCaptcha =
          "data:image/jpg;base64," +
          Buffer.from(this.$store.state.Members.respCaptcha, "binary").toString(
            "base64"
          );
      } else {
        this.imgCaptcha = "";
      }
    },
    async submitForm() {
      this.prefix = await localStorage.getItem("x-prefix");

      if (!this.prefix) {
        return;
      }

      let optionts = {
        headers: { prefix: this.prefix },
        form: {
          ...this.form,
          ...this.getRegisterForm.formRegisterStep1,
          ...this.getRegisterForm.formRegisterStep2,
        },
      };
      await this.$store.dispatch("Members/register_step3", optionts);
      if (this.$store.state.Members.statusCode === 200) {
        this.$router.push({
          path: "/register-4",
          query: { prefix: this.prefix },
        });
      } else {
        // console.log(this.$store.state.Members.responseError.message);
        this.notifyError(this.$store.state.Members.responseError.message);
      }
    },
    notifyError(msg = "") {
      // add dark black ground
      document.body.classList.add("dark-overlay");
      // Failed case
      this.$snotify
        .error("ไม่สำเร็จ", msg, {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: false,
          buttons: [
            {
              text: "ปิดหน้าต่าง",
              action: null,
            },
          ],
        })
        .on("destroyed", () => {
          document.body.classList.remove("dark-overlay");
        });
    },
  },
  computed: {
    ...mapGetters("Members", ["getRegisterForm"]),
  },
  watch: {
    dataPrefix: function () {
      // console.log("dataPrefix:", this.dataPrefix);
    },
  },
  async mounted() {
    console.log("register3 page");
    this.prefix = await localStorage.getItem("x-prefix");
    if (
      this.getRegisterForm.formRegisterStep1 == null ||
      this.getRegisterForm.formRegisterStep2 == null
    ) {
      this.$router.push({
        path: "/register-1",
        query: { prefix: this.prefix },
      });
    }
    await this.callRegisterCaptchaImage();
    // this.$refs.bank_code.focus();
  },
};
</script>