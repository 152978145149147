<template>
  <div>
    <footer class="mb-show">
      <div class="container">
        <div class="copyright">
          © COPYRIGHT 2021
            <router-link to="/">EURO-WALLET.COM</router-link>.<br
            class="d-block d-md-none"
          />
          ALL RIGHTS RESERVED.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Footer",
  components: {
    // HelloWorld,
  },
};
</script>
