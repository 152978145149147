<template>
  <div>
    <Header :headerClass="true" menuName="ฝากเงิน"/>
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>ฝากเงิน</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <h1 class="e-wrapper__title">ฝากเงิน</h1>

        <!-- Label Noti -->
        <div class="e-label e-label--danger">
          <i class="far fa-bullhorn"></i>
          <span
            >ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน
            <strong>ภายใน 8 วินาที</strong></span
          >
        </div>
        <div class="e-label e-label--warning">
          <i class="far fa-exclamation-square"></i>
          <span
            >สามารถโอนเข้าธนาคารไหนก็ได้ โดยใช้
            <strong>บัญชีและเบอร์โทร</strong> ที่สมัครโอนเข้ามาเท่านั้น</span
          >
        </div>

        <!-- Bank -->
        <div class="e-banks">
          <div class="e-bank">
            <div class="e-bank__logo" data-logo="scb"></div>
            <div class="e-bank__info">
              <div class="e-bank__info--text">
                <small>ธนาคารไทยพาณิชย์</small>
                <h2>4300731347</h2>
                <span>จิตตวดี แปงกล</span>
              </div>
              <div class="e-bank__info--btn">
                <div class="e-btn--copy-squre" onclick="toggleModal()">
                  <i class="far fa-clone"></i>
                  <span>คัดลอก</span>
                </div>
              </div>
            </div>
          </div>
          <div class="e-bank">
            <div class="e-bank__logo" data-logo="scb"></div>
            <div class="e-bank__info">
              <div class="e-bank__info--text">
                <small>ธนาคารไทยพาณิชย์</small>
                <h2>4350367708</h2>
                <span>น้ำผึ้ง กุมขุนทด</span>
              </div>
              <div class="e-bank__info--btn">
                <div class="e-btn--copy-squre" onclick="toggleModal()">
                  <i class="far fa-clone"></i>
                  <span>คัดลอก</span>
                </div>
              </div>
            </div>
          </div>
          <div class="e-bank">
            <div class="e-bank__logo" data-logo="tw"></div>
            <div class="e-bank__info">
              <div class="e-bank__info--text">
                <small>ทรูมันนี่วอลเล็ท</small>
                <h2>0622786088</h2>
                <span>พิริยะ ชมภู่</span>
              </div>
              <div class="e-bank__info--btn">
                <div class="e-btn--copy-squre" onclick="toggleModal()">
                  <i class="far fa-clone"></i>
                  <span>คัดลอก</span>
                </div>
              </div>
            </div>
          </div>
          <div class="e-bank">
            <div class="e-bank__logo" data-logo="tw"></div>
            <div class="e-bank__info">
              <div class="e-bank__info--text">
                <small>ทรูมันนี่วอลเล็ท</small>
                <h2>0649970480</h2>
                <span>ทองสุข ชินมาศ</span>
              </div>
              <div class="e-bank__info--btn">
                <div class="e-btn--copy-squre" onclick="toggleModal()">
                  <i class="far fa-clone"></i>
                  <span>คัดลอก</span>
                </div>
              </div>
            </div>
          </div>
          <div class="e-bank">
            <div class="e-bank__logo" data-logo="tw"></div>
            <div class="e-bank__info">
              <div class="e-bank__info--text">
                <small>ทรูมันนี่วอลเล็ท</small>
                <h2>0972807251</h2>
                <span>เกรียงไกร สุขจรัส</span>
              </div>
              <div class="e-bank__info--btn">
                <div class="e-btn--copy-squre" onclick="toggleModal()">
                  <i class="far fa-clone"></i>
                  <span>คัดลอก</span>
                </div>
              </div>
            </div>
          </div>
          <div class="e-bank">
            <div class="e-bank__logo" data-logo="tw"></div>
            <div class="e-bank__info">
              <div class="e-bank__info--text">
                <small>ทรูมันนี่วอลเล็ท</small>
                <h2>0979273702</h2>
                <span>จตุรงค์ อาจวงษ์</span>
              </div>
              <div class="e-bank__info--btn">
                <div class="e-btn--copy-squre" onclick="toggleModal()">
                  <i class="far fa-clone"></i>
                  <span>คัดลอก</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
    <Tabs />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";

export default {
  name: "Deposit",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  methods: {
    toggleModal() {
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
  },
};
</script>
