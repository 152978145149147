import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios'
Vue.use(Vuex)

const PrefixStore = {
    namespaced: true,
    state: {
        statusCode: 0,
        responseError: null,
        respVerify: null,
    },
    getters: {
        getPrefix: state => {
            return state.respVerify.data
        }
    },
    mutations: {
        setStatusCode(state, data) {
            state.statusCode = data
        },
        setResponseError(state, data) {
            state.responseError = data
        },
        setVerify(state, data) {
            state.respVerify = data
        },
    },
    actions: {

        async verify(context, options) {
            let data = await axios.get(Vue.prototype.$URL_API_V1 + "/prefix", {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix,
                }
            }).then((r) => {
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })
            context.commit("setVerify", data)
        },

    }
}


export default PrefixStore