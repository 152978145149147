<template>
  <div>
    <div class="container">
      <div class="full-height">
        <div class="e-login">
          <div class="e-login__body">
            <router-link to="/" class="e-header__logo">
              <!-- <img src="../assets/images/logo.svg" alt="logo euro wallet" /> -->
              <img :src="dataPrefix.logo" alt="logo euro wallet" />
            </router-link>

            <form @submit.prevent="login">
              <fieldset class="form-group">
                <label>เบอร์โทรศัพท์</label>
                <input
                  type="tel"
                  class="form-control"
                  required="required"
                  placeholder="เบอร์โทรศัพท์"
                  ref="phone"
                  inputmode="tel"
                  v-model="form.phone"
                />
              </fieldset>
              <fieldset class="form-group">
                <label>รหัสผ่าน</label>
                <input
                  type="password"
                  class="form-control"
                  required="required"
                  placeholder="รหัสผ่าน"
                  ref="password"
                  v-model="form.password"
                />
              </fieldset>
              <!-- <button type="submit" class="e-btn--primary" @click="showNotify">เข้าสู่ระบบ</button> -->
              <button type="submit" class="e-btn--primary">เข้าสู่ระบบ</button>
              <span>
                ต้องการสมัครสมาชิกใช่ไหม?
                <router-link
                  :to="{ path: 'register-1', query: { prefix: prefix } }"
                  >สมัครสมาชิก</router-link
                >
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--
      Add vue-snotify tag.
      If you want to use the default theme, delete ".snotify-custom" class.
    -->
    <vue-snotify class="snotify-custom"></vue-snotify>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  props: ["dataPrefix"],
  data() {
    return {
      prefix: "",
      form: {
        phone: "",
        password: "",
      },
      respLogin: {},
    };
  },
  methods: {
    async login() {
      this.prefix = await localStorage.getItem("x-prefix");

      if (!this.prefix) {
        return;
        // this.form.password.focus()
        // this.$refs.username.$el.focus()
      }

      let optionts = {
        headers: { prefix: this.prefix },
        form: this.form,
      };
      await this.$store.dispatch("Members/login", optionts);
      if (this.$store.state.Members.statusCode === 200) {
        this.respLogin = await this.$store.state.Members.respLogin;
        optionts.headers.token = this.respLogin.token;
        await this.$store.dispatch("Members/me", optionts);
        await localStorage.setItem("token", this.respLogin.token);
        this.notifySuccess(this.respLogin.message);
      } else {
        // alert(this.$store.state.Auth.responseError.messages);
        // await this.recaptcha();
        // await localStorage.removeItem("backoffice:user_info");
        await localStorage.removeItem("token");
        // console.log(this.$store.state.Members);
        this.notifyError(this.$store.state.Members.responseError.message);
      }

      // this.$router.push({
      //   path: "/menus/dashboard",
      //   query: { prefix: this.prefix },
      // });
      // this.router.push({ path: "/menus/dashboard" });
    },
    notifySuccess(msg = "") {
      // add dark black ground
      document.body.classList.add("dark-overlay");
      // Success case
      this.$snotify
        .success("สำเร็จ", msg, {
          timeout: 1000,
          showProgressBar: false,
          closeOnClick: true,
          // buttons: [
          //   {
          //     text: "ยืนยัน",
          //     action: () => {
          //       this.$router.push({
          //         path: "/menus/dashboard",
          //         query: { prefix: this.prefix },
          //       });
          //     },
          //   },
          // ],
        })
        .on("destroyed", () => {
          document.body.classList.remove("dark-overlay");
          this.$router.push({
            path: "/menus/dashboard",
            query: { prefix: this.prefix },
          });
        });
    },
    notifyError(msg = "") {
      // add dark black ground
      document.body.classList.add("dark-overlay");
      // Failed case
      this.$snotify
        .error("ไม่สำเร็จ", msg, {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: false,
          buttons: [
            {
              text: "ปิดหน้าต่าง",
              action: null,
            },
          ],
        })
        .on("destroyed", () => {
          document.body.classList.remove("dark-overlay");
        });
    },
  },
  watch: {
    dataPrefix: function () {
      // console.log("dataPrefix:", this.dataPrefix);
    },
  },
  async mounted() {
    console.log("login page");
    // this.prefix = this.$route.query.prefix.trim().toUpperCase().replace(/[^A-Z0-9]/g, "");
    this.prefix = await localStorage.getItem("x-prefix");
    // this.prefix = await this.$store.state.Prefix.respVerify.data.prefix;
    this.$refs.phone.focus();
    // this.$refs.password.focus();
    // console.log("prefix", this.prefix);
  },
};
</script>
