<template>
  <div>
    <Header :headerClass="true" menuName="ประวัติ" />
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>ประวัติ</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <h1 class="e-wrapper__title">ประวัติ</h1>

        <div id="tabMenu" class="e-tab" v-on:click="activeTab(event)">
          <div
            class="e-tab__link e-tab__link--active"
            v-on:click="openHistory('deposit')"
          >
            ฝากเงิน
          </div>
          <div class="e-tab__link" v-on:click="openHistory('withdraw')">
            ถอนเงิน
          </div>
          <div class="e-tab__link" v-on:click="openHistory('bonus')">โบนัส</div>
          <div class="e-tab__link" v-on:click="openHistory('affiliate')">
            ชวนเพื่อน
          </div>
          <div class="e-tab__link" v-on:click="openHistory('luckyspin')">
            กงล้อเสี่ยงโชค
          </div>
          <div class="e-tab__link" v-on:click="openHistory('cashback')">
            แคชแบ็ก
          </div>
        </div>

        <!-- Deposit -->
        <div
          class="js-tab-content"
          data-category="deposit"
          style="display: block"
        >
          <div class="e-history">
            <div class="e-history__content">
              <h1>ฝากเงิน <span class="pending">รอดำเนินการ</span></h1>
              <p>ทรูมันนี่วอลเล็ท</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 300.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
            <div class="e-history__remark">
              <small>* ทดสอบการใส่หมายเหตุ</small>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>ฝากเงิน <span class="success">สำเร็จ</span></h1>
              <p>ทรูมันนี่วอลเล็ท</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 20,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>ฝากเงิน <span class="fail">ไม่สำเร็จ</span></h1>
              <p>ทรูมันนี่วอลเล็ท</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 50,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
        </div>

        <!-- Withdraw -->
        <div
          class="js-tab-content"
          data-category="withdraw"
          style="display: none"
        >
          <div class="e-history">
            <div class="e-history__content">
              <h1>ถอนเงิน <span class="pending">รอดำเนินการ</span></h1>
              <p>ธนาคารกสิกรไทย</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 300.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>ถอนเงิน <span class="success">สำเร็จ</span></h1>
              <p>ธนาคารกสิกรไทย</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 20,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>ถอนเงิน <span class="fail">ไม่สำเร็จ</span></h1>
              <p>ธนาคารกสิกรไทย</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 50,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
        </div>

        <!-- Bonus -->
        <div class="js-tab-content" data-category="bonus" style="display: none">
          <div class="e-history">
            <div class="e-history__content">
              <h1>โบนัส <span class="pending">รอดำเนินการ</span></h1>
              <p>สมัครสมาชิกใหม่ 30%</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 300.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
            <div class="e-history__remark">
              <small>* ยอดเทิร์นที่ต้องทำคือ 9,000 บาท</small>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>โบนัส <span class="success">สำเร็จ</span></h1>
              <p>สมัครสมาชิกใหม่ 30%</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 20,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>โบนัส <span class="fail">ไม่สำเร็จ</span></h1>
              <p>สมัครสมาชิกใหม่ 30%</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 50,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
        </div>

        <!-- Affiliate -->
        <div
          class="js-tab-content"
          data-category="affiliate"
          style="display: none"
        >
          <div class="e-history">
            <div class="e-history__content">
              <h1>ชวนเพื่อน <span class="pending">รอดำเนินการ</span></h1>
              <p>โยกเงินเข้ากระเป๋าหลัก</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 300.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>ชวนเพื่อน <span class="success">สำเร็จ</span></h1>
              <p>โยกเงินเข้ากระเป๋าหลัก</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 20,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>ชวนเพื่อน <span class="fail">ไม่สำเร็จ</span></h1>
              <p>โยกเงินเข้ากระเป๋าหลัก</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 50,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
        </div>

        <!-- Lucky Spin -->
        <div
          class="js-tab-content"
          data-category="luckyspin"
          style="display: none"
        >
          <div class="e-history">
            <div class="e-history__content">
              <h1>กงล้อ <span class="pending">รอดำเนินการ</span></h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>ทอง 1 บาท</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>กงล้อ <span class="success">สำเร็จ</span></h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 20,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>กงล้อ <span class="fail">ไม่สำเร็จ</span></h1>
              <p>รับรางวัล</p>
            </div>
            <div class="e-history__content">
              <h1>iPhone 12</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
        </div>

        <!-- Cashback -->
        <div
          class="js-tab-content"
          data-category="cashback"
          style="display: none"
        >
          <div class="e-history">
            <div class="e-history__content">
              <h1>เงินคืน <span class="pending">รอดำเนินการ</span></h1>
              <p>โยกเงินเข้ากระเป๋าหลัก</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 300.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>เงินคืน <span class="success">สำเร็จ</span></h1>
              <p>โยกเงินเข้ากระเป๋าหลัก</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 20,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
          <div class="e-history">
            <div class="e-history__content">
              <h1>เงินคืน <span class="fail">ไม่สำเร็จ</span></h1>
              <p>โยกเงินเข้ากระเป๋าหลัก</p>
            </div>
            <div class="e-history__content">
              <h1>฿ 1,000.00</h1>
              <p>24/05/2020 07:14:40</p>
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
    <Tabs />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";

export default {
  name: "History",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  methods: {
    toggleModal() {
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
    openHistory(category) {
      const cardHistory = document.querySelectorAll(".js-tab-content");

      [].forEach.call(cardHistory, function (history) {
        if (history.dataset.category === category) {
          history.style.display = "block";
          history.classList.add("fade-in");
          setTimeout(function () {
            history.classList.remove("fade-in");
          }, 500);
        } else {
          history.style.display = "none";
        }
      });
    },
    activeTab(e) {
      const elems = document.querySelectorAll(".e-tab__link--active");

      if (e.target.className === "e-tab__link") {
        [].forEach.call(elems, function (el) {
          el.classList.remove("e-tab__link--active");
        });

        e.target.className = "e-tab__link e-tab__link--active";
      }
    },
  },
};
</script>
