<template>
  <div>
    <Header :headerClass="true" menuName="ถอนเงิน" />
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>ถอนเงิน</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <h1 class="e-wrapper__title e-wrapper__title--center">ถอนเงิน</h1>

        <div class="e-withdraw">
          <div class="e-withdraw__head">
            <div class="e-withdraw__head--info">
              <div class="wallet-icon">
                <i class="fas fa-wallet"></i>
              </div>
              <span>กระเป๋าหลัก</span>
              <h1>฿ 7,135.40</h1>
            </div>
            <div class="e-withdraw__head--info">
              <div class="e-bank__logo" data-logo="bbl"></div>
              <span>ธนาคารกรุงเทพ</span>
              <h1>1234567890</h1>
            </div>
            <i class="far fa-arrow-right"></i>
          </div>
          <div class="e-withdraw__body">
            <form action="withdraw.html">
              <fieldset class="form-group">
                <label>จำนวนเงินที่ต้องการถอน (บาท)</label>
                <input
                  type="number"
                  class="form-control"
                  required="required"
                  placeholder="0"
                />
                <small>* ถอนขั้นต่ำ 300 บาท</small>
              </fieldset>
              <button type="submit" class="e-btn--primary">ถอนเงิน</button>
            </form>
          </div>
        </div>
      </section>
    </div>

    <Footer />
    <Tabs />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";

export default {
  name: "Withdraw",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  methods: {
    toggleModal() {
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
  },
};
</script>
