<template>
  <div>
    <Header :headerClass="true" menuName="ดาวน์โหลด" />
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>ดาวน์โหลด</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <h1 class="e-wrapper__title">ดาวน์โหลด</h1>

        <div class="e-downloads">
          <div class="e-download">
            <div class="e-download__logo">
              <img src="../../assets/images/slot-logo/ambpoker.png" alt="" />
            </div>
            <div class="e-download__btn">
              <a href="#">
                <i class="fab fa-android"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Android</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-apple"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>iOS</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-windows"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Desktop</h3>
                </div>
              </a>
            </div>
          </div>

          <div class="e-download">
            <div class="e-download__logo">
              <img src="../../assets/images/slot-logo/dragoonsoft.png" alt="" />
            </div>
            <div class="e-download__btn">
              <a href="#">
                <i class="fab fa-android"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Android</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-apple"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>iOS</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-windows"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Desktop</h3>
                </div>
              </a>
            </div>
          </div>

          <div class="e-download">
            <div class="e-download__logo">
              <img src="../../assets/images/slot-logo/pgslot.png" alt="" />
            </div>
            <div class="e-download__btn">
              <a href="#">
                <i class="fab fa-android"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Android</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-apple"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>iOS</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-windows"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Desktop</h3>
                </div>
              </a>
            </div>
          </div>

          <div class="e-download">
            <div class="e-download__logo">
              <img src="../../assets/images/slot-logo/gamatron.png" alt="" />
            </div>
            <div class="e-download__btn">
              <a href="#">
                <i class="fab fa-android"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Android</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-apple"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>iOS</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-windows"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Desktop</h3>
                </div>
              </a>
            </div>
          </div>

          <div class="e-download">
            <div class="e-download__logo">
              <img src="../../assets/images/slot-logo/slotxo.png" alt="" />
            </div>
            <div class="e-download__btn">
              <a href="#">
                <i class="fab fa-android"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Android</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-apple"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>iOS</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-windows"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Desktop</h3>
                </div>
              </a>
            </div>
          </div>

          <div class="e-download">
            <div class="e-download__logo">
              <img src="../../assets/images/slot-logo/ameba.png" alt="" />
            </div>
            <div class="e-download__btn">
              <a href="#">
                <i class="fab fa-android"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Android</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-apple"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>iOS</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-windows"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Desktop</h3>
                </div>
              </a>
            </div>
          </div>

          <div class="e-download">
            <div class="e-download__logo">
              <img src="../../assets/images/slot-logo/live22.png" alt="" />
            </div>
            <div class="e-download__btn">
              <a href="#">
                <i class="fab fa-android"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Android</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-apple"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>iOS</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-windows"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Desktop</h3>
                </div>
              </a>
            </div>
          </div>

          <div class="e-download">
            <div class="e-download__logo">
              <img src="../../assets/images/slot-logo/spadegaming.png" alt="" />
            </div>
            <div class="e-download__btn">
              <a href="#">
                <i class="fab fa-android"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Android</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-apple"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>iOS</h3>
                </div>
              </a>
              <a href="#">
                <i class="fab fa-windows"></i>
                <div>
                  <small>เล่นผ่าน</small>
                  <h3>Desktop</h3>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
    <Tabs />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";

export default {
  name: "Download",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  methods: {
    toggleModal() {
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
  },
};
</script>
