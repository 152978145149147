import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register1 from '../views/Register1.vue'
import Register2 from '../views/Register2.vue'
import Register3 from '../views/Register3.vue'
import Register4 from '../views/Register4.vue'
import Profile from '../views/menus/account/Profile.vue'
import Dashboard from '../views/menus/Dashboard.vue'
import Deposit from '../views/menus/Deposit.vue'
import Withdraw from '../views/menus/Withdraw.vue'
import Promotion from '../views/menus/Promotion.vue'
import History from '../views/menus/History.vue'
import Transfer from '../views/menus/Transfer.vue'
import Affiliate from '../views/menus/Affiliate.vue'
import Download from '../views/menus/Download.vue'
import LuckySpin from '../views/menus/LuckySpin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'Index',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register-1',
    name: 'Register-1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Register1,
    // component: () => import(/* webpackChunkName: "about" */ '../views/Register1.vue')
  },
  {
    path: '/register-2',
    name: 'Register-2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Register2,
    // component: () => import(/* webpackChunkName: "about" */ '../views/Register2.vue')
  },
  {
    path: '/register-3',
    name: 'Register-3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Register3,
    // component: () => import(/* webpackChunkName: "about" */ '../views/Register3.vue')
  },
  {
    path: '/register-4',
    name: 'Register-4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Register4,
    // component: () => import(/* webpackChunkName: "about" */ '../views/Register4.vue')
  },
  {
    path: '/menus/account',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Profile,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/account/Profile.vue')
  },
  {
    path: '/menus/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Dashboard,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/Dashboard.vue')
  },
  {
    path: '/menus/deposit',
    name: 'Deposit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Deposit,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/Deposit.vue')
  },
  {
    path: '/menus/withdraw',
    name: 'Withdraw',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Withdraw,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/Withdraw.vue')
  },
  {
    path: '/menus/promotion',
    name: 'Promotion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Promotion,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/Promotion.vue')
  },
  {
    path: '/menus/history',
    name: 'History',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: History,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/History.vue')
  },
  {
    path: '/menus/transfer',
    name: 'Transfer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Transfer,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/Transfer.vue')
  },
  {
    path: '/menus/affiliate',
    name: 'Affiliate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Affiliate,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/Affiliate.vue')
  },
  {
    path: '/menus/download',
    name: 'Download',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Download,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/Download.vue')
  },
  {
    path: '/menus/lucky-spin',
    name: 'LuckySpin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LuckySpin,
    // component: () => import(/* webpackChunkName: "about" */ '../views/menus/LuckySpin.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
})

const DEFAULT_TITLE = 'EURO-WALLET';

export const createPath = (path) => {
  const prefix = localStorage.getItem('x-prefix')

  return {
    path: path,
    query: {
      prefix: typeof prefix === 'string' ? prefix.toUpperCase().replace(/[^A-Z0-9]/g, "") : 'NO-PREFIX',
    },
  }
}
// export const isLoggedIn = async () => {
//   let token = localStorage.getItem("token");
//   if (!token) {
//     localStorage.removeItem("token");
//     // await localStorage.removeItem("info_member");
//     return false;
//   }
//   return true;

//   // let optionts = {
//   //   headers: {
//   //     prefix: to.query.prefix,
//   //     token: token,
//   //   },
//   // };
//   // await router.app.$store.dispatch("Members/me", optionts);
//   // if (router.app.$store.state.Members.statusCode === 200) {
//   //   await localStorage.setItem("info_member", JSON.stringify(router.app.$store.state.Members.respMe.data));
//   //   return true;
//   // } else {
//   //   // alert(this.$store.state.Auth.responseError.messages);
//   //   await localStorage.removeItem("token");
//   //   await localStorage.removeItem("info_member");
//   // }
//   // return false;
// }

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/register-1', '/register-2', '/register-3', '/register-4']
  const authRequired = !publicPages.includes(to.path)

  if (authRequired) {

    let resultIsLoggedIn = false;
    // await isLoggedIn().then((result) => {
    //   resultIsLoggedIn = result;
    // })

    let token = localStorage.getItem("token");
    if (token) {
      resultIsLoggedIn = true;
    }

    if (!to.query.prefix && to.query.rqs != '') {
      if (resultIsLoggedIn) {
        next(createPath(to.path))
      } else {
        next("/login")
      }
    } else {
      if (resultIsLoggedIn) {
        next()
      } else {
        next("/login")
      }
    }

  } else {

    if (!to.query.prefix && to.query.rqs != '') {
      next(createPath(to.path))
    } else {
      next()
    }

  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    const MyTitle = localStorage.getItem('title');

    document.title = to.meta.title || (to.name + ' | ' + (!MyTitle ? DEFAULT_TITLE: MyTitle));
  })
})

export default router
