import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios'
Vue.use(Vuex)

const MembersStore = {
    namespaced: true,
    state: {
        statusCode: 0,
        responseError: null,
        respLogin: null,
        respMe: null,

        respCaptcha: null,

        respRegisterStep1: null,
        respRegisterStep2: null,
        respRegisterStep3: null,

        formRegisterStep1: null,
        formRegisterStep2: null,
        formRegisterStep3: null,
    },
    getters: {
        getProfile: (state) => {
            return state.respMe.data;
        },
        getRegisterForm: (state) => {
            return {
                formRegisterStep1: state.formRegisterStep1,
                formRegisterStep2: state.formRegisterStep2,
                formRegisterStep3: state.formRegisterStep3,
            };
        },
    },
    mutations: {
        setStatusCode(state, data) {
            state.statusCode = data
        },

        setResponseError(state, data) {
            state.responseError = data
        },

        setLogin(state, data) {
            state.respLogin = data
        },

        setMe(state, data) {
            state.respMe = data
        },

        setCaptcha(state, data) {
            state.respCaptcha = data
        },

        setRegisterStep1(state, data) {
            state.respRegisterStep1 = data
        },

        setRegisterStep2(state, data) {
            state.respRegisterStep2 = data
        },

        setRegisterStep3(state, data) {
            state.respRegisterStep3 = data
        },

        setFormRegisterStep1(state, data) {
            state.formRegisterStep1 = data
        },

        setFormRegisterStep2(state, data) {
            state.formRegisterStep2 = data
        },

        setFormRegisterStep3(state, data) {
            state.formRegisterStep3 = data
        },

        resetFormRegister(state) {
            state.respRegisterStep1 = null;
            state.respRegisterStep2 = null;
            state.respRegisterStep3 = null;
            state.formRegisterStep1 = null;
            state.formRegisterStep2 = null;
            state.formRegisterStep3 = null;
        },
    },
    actions: {
        async login(context, options) {
            let data = await axios.post(Vue.prototype.$URL_API_V1 + "/auth/login", JSON.stringify(options.form), {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix
                }
            }).then((r) => {
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })

            context.commit("setLogin", data)
        },

        async me(context, options) {
            let data = await axios.get(Vue.prototype.$URL_API_V1 + "/auth/me", {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix,
                    'Authorization': 'Bearer ' + options.headers.token,
                }
            }).then((r) => {
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })

            context.commit("setMe", data)
        },

        async register_captcha(context, options) {
            let data = await axios.get(Vue.prototype.$URL_API_V1 + "/auth/register-captcha", {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix,
                },
                responseType: 'arraybuffer',
            }).then((r) => {
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })

            context.commit("setCaptcha", data)
        },

        async register_step1(context, options) {
            let data = await axios.post(Vue.prototype.$URL_API_V1 + "/auth/register-step-1", JSON.stringify(options.form), {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix
                }
            }).then((r) => {
                if (r.status == 200) {
                    context.commit("setFormRegisterStep1", options.form)
                }
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })

            context.commit("setRegisterStep1", data)
        },

        async register_step2(context, options) {
            let data = await axios.post(Vue.prototype.$URL_API_V1 + "/auth/register-step-2", JSON.stringify(options.form), {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix
                }
            }).then((r) => {
                if (r.status == 200) {
                    context.commit("setFormRegisterStep2", options.form)
                }
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })

            context.commit("setRegisterStep2", data)
        },

        async register_step3(context, options) {
            let data = await axios.post(Vue.prototype.$URL_API_V1 + "/auth/register-step-3", JSON.stringify(options.form), {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix
                }
            }).then((r) => {
                if (r.status == 200) {
                    context.commit("setFormRegisterStep3", options.form)
                }
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })

            context.commit("setRegisterStep3", data)
        },
        
        resetFormRegister(context) {
            context.commit("resetFormRegister")
        },
    }

}

export default MembersStore