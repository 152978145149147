<template>
  <div>
    <header>
      <div class="container">
        <div :class="`e-header` + (headerClass ? ' e-header--sub-page' : '')">
          <router-link
            to="/menus/dashboard"
            class="e-header__back"
            v-if="headerClass"
          >
            <i class="far fa-arrow-left"></i>
          </router-link>
          <h1 class="e-header__title" v-if="headerClass">{{ menuName }}</h1>

          <router-link to="/" class="e-header__logo">
            <!-- <img src="../../assets/images/logo.svg" alt="logo euro wallet" /> -->
            <img :src="getPrefix.logo" alt="logo euro wallet" />
          </router-link>
          <div class="e-header__user">
            <div class="e-header__user--btn" v-on:click="openMenuProfile()">
              <img
                class="js-profile-btn"
                src="../../assets/images/profile-placeholder.png"
                alt="profile placeholder"
              />
              <span class="js-profile-btn"
                >{{ getProfile.first_name }} {{ getProfile.last_name }}
                <i class="far fa-chevron-down js-profile-btn"></i
              ></span>
            </div>

            <ul class="e-menu-profile">
              <li class="e-menu-profile__link">
                <router-link to="/menus/account">
                  <i class="fal fa-user"></i> โปรไฟล์ของคุณ
                </router-link>
              </li>
              <li class="e-menu-profile__link">
                <a
                  href="javascript:void(0);"
                  @click="logout"
                  class="e-menu-profile--logout"
                >
                  <i class="fal fa-sign-out"></i> ออกจากระบบ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import {  mapGetters } from "vuex";

export default {
  name: "Header",
  props: {
    headerClass: Boolean,
    menuName: String,
  },
  components: {
    // HelloWorld,
  },
  data() {
    return {
      prefix: "",
    };
  },
  methods: {
    async logout() {
      await localStorage.removeItem("token");
      await localStorage.removeItem("vuex");
      this.$router.push({ path: "/login", query: { prefix: this.prefix } });
    },
    openMenuProfile() {
      const element = document.getElementsByClassName("e-menu-profile")[0];
      element.classList.toggle("open");
    },
    async callProfile() {
      console.log("func callProfile");
      let token = localStorage.getItem("token");
      if (!token) {
        await localStorage.removeItem("token");
        return false;
      }
      let optionts = {
        headers: { prefix: this.prefix, token: token },
      };
      await this.$store.dispatch("Members/me", optionts);
      if (this.$store.state.Members.statusCode === 200) {
        // this.respMe = this.$store.getters["Members/getProfile"].data;
        // console.log(this.$store.state.Members.respMe.data);
        return true;
      }
      await localStorage.removeItem("token");
      return false;
    },
  },
  computed: {
    // ...mapState('Members', ['respMe']),
    ...mapGetters('Prefix', ['getPrefix']),
    ...mapGetters('Members', ['getProfile'])
    // ...mapGetters([
    //   'Members/getProfile',
    //   // ...
    // ])
  },
  async mounted() {
    console.log("header page");
    this.prefix = await localStorage.getItem("x-prefix");
    // this.prefix = this.$store.state.Prefix.respVerify.data.prefix;
    let callProfile = await this.callProfile();
    if (!callProfile) {
      this.$router.push({
        path: "/login",
        query: { prefix: this.prefix },
      });
    }
  },
};
</script>
