import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios'
Vue.use(Vuex)

const GamesStore = {
    namespaced: true,
    state: {
        statusCode: 0,
        responseError: null,

        respCreate: null,
        respBalance: null,
    },
    getters: {

    },
    mutations: {
        setStatusCode(state, data) {
            state.statusCode = data
        },

        setResponseError(state, data) {
            state.responseError = data
        },

        setBalance(state, data) {
            state.respBalance = data
        },

        setCreate(state, data) {
            state.respCreate = data
        },
    },
    actions: {

        async create(context, options) {
            let data = await axios.post(Vue.prototype.$URL_API_V1 + "/game?provider=" + options.form.provider, {}, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix,
                    'Authorization': 'Bearer ' + options.headers.token,
                }
            }).then((r) => {
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })

            context.commit("setCreate", data)
        },

        async balance(context, options) {
            let data = await axios.get(Vue.prototype.$URL_API_V1 + "/game-balance?provider=" + options.form.provider, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'X-Prefix': options.headers.prefix,
                    'Authorization': 'Bearer ' + options.headers.token,
                }
            }).then((r) => {
                context.commit("setStatusCode", r.status)
                context.commit("setResponseError", null)
                return r.data
            }).catch((err) => {
                context.commit("setStatusCode", err.response.status)
                context.commit("setResponseError", err.response.data)
            })

            context.commit("setBalance", data)
        },

    }

}

export default GamesStore