<template>
  <div>
    <Header />
    <Notice />

    <div class="container">
      <section class="e-wallet">
        <div class="e-wallet__amount">
          <span>กระเป๋าหลัก</span>
          <h1>฿ {{ getProfile.money.toFixed(2) }}</h1>
        </div>
        <div class="e-wallet__btn">
          <router-link
            to="/menus/deposit"
            class="e-menu-btn e-menu-btn--no-border"
          >
            <i class="far fa-arrow-to-bottom"></i>
            <span>ฝากเงิน</span>
          </router-link>
          <router-link
            to="/menus/withdraw"
            class="e-menu-btn e-menu-btn--no-border"
          >
            <i class="far fa-arrow-from-bottom"></i>
            <span>ถอนเงิน</span>
          </router-link>
        </div>
      </section>

      <section class="e-menu">
        <router-link to="/menus/deposit" class="e-menu-btn">
          <i class="far fa-arrow-to-bottom"></i>
          <span>ฝากเงิน</span>
        </router-link>
        <router-link to="/menus/withdraw" class="e-menu-btn">
          <i class="far fa-arrow-from-bottom"></i>
          <span>ถอนเงิน</span>
        </router-link>
        <router-link to="/menus/promotion" class="e-menu-btn">
          <i class="far fa-gift"></i>
          <span>โปรโมชั่น</span>
        </router-link>
        <router-link to="/menus/history" class="e-menu-btn">
          <i class="far fa-history"></i>
          <span>ประวัติ</span>
        </router-link>
        <router-link to="/menus/transfer" class="e-menu-btn">
          <i class="far fa-exchange"></i>
          <span>โยกเงิน</span>
        </router-link>
        <router-link to="/menus/affiliate" class="e-menu-btn">
          <i class="far fa-users"></i>
          <span>แนะนำเพื่อน</span>
        </router-link>
        <router-link to="/menus/download" class="e-menu-btn">
          <i class="far fa-gamepad-alt"></i>
          <span>ดาวน์โหลด</span>
        </router-link>
        <router-link to="/menus/lucky-spin" class="e-menu-btn">
          <i class="far fa-life-ring"></i>
          <span>วงล้อลุ้นโชค</span>
        </router-link>
      </section>

      <section class="e-games">
        <h1>บัญชีเกม</h1>

        <div class="e-games__row">
          <!-- Active -->
          <div
            class="e-game"
            v-for="(game, index) in listGames"
            :class="!game.enable || game.balance == 'ยังไม่ได้สมัคร' ? 'inactivate' : ''"
            :key="index"
          >
            <div class="e-game__logo">
              <img :src="listLogo[game.key]" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>{{ game.key }}</h2>
                <span>{{ game.balance }}</span>
              </div>
              <div class="e-game__info--btn">
                <div
                  class="e-btn--info"
                  v-if="game.balance != 'ยังไม่ได้สมัคร'"
                  v-on:click="toggleModal(game)"
                >
                  <i class="fal fa-info-circle"></i>
                  <span>ข้อมูลบัญชี</span>
                </div>
                <div
                  class="e-btn--activate"
                  v-else
                  v-on:click="createGame(game.provider, index)"
                >
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div>

          <!-- inActive -->
          <!-- <div class="e-game inactivate">
            <div class="e-game__logo">
              <img src="../../assets/images/slot-logo/ambpoker.png" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>AMB POKER</h2>
                <span>฿ 150.00</span>
              </div>
              <div class="e-game__info--btn">
                <div class="e-btn--activate">
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div>

          <div class="e-game inactivate">
            <div class="e-game__logo">
              <img src="../../assets/images/slot-logo/dragoonsoft.png" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>DRAGOON SOFT</h2>
                <span>ยังไม่ได้สมัคร</span>
              </div>
              <div class="e-game__info--btn">
                <div class="e-btn--activate">
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div>

          <div class="e-game inactivate">
            <div class="e-game__logo">
              <img src="../../assets/images/slot-logo/pgslot.png" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>PGSLOT</h2>
                <span>ยังไม่ได้สมัคร</span>
              </div>
              <div class="e-game__info--btn">
                <div class="e-btn--activate">
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div>

          <div class="e-game inactivate">
            <div class="e-game__logo">
              <img src="../../assets/images/slot-logo/gamatron.png" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>GAMATRON</h2>
                <span>ยังไม่ได้สมัคร</span>
              </div>
              <div class="e-game__info--btn">
                <div class="e-btn--activate">
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div>

          <div class="e-game inactivate">
            <div class="e-game__logo">
              <img src="../../assets/images/slot-logo/slotxo.png" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>SLOTXO</h2>
                <span>ยังไม่ได้สมัคร</span>
              </div>
              <div class="e-game__info--btn">
                <div class="e-btn--activate">
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div>

          <div class="e-game inactivate">
            <div class="e-game__logo">
              <img src="../../assets/images/slot-logo/ameba.png" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>AMEBA</h2>
                <span>ยังไม่ได้สมัคร</span>
              </div>
              <div class="e-game__info--btn">
                <div class="e-btn--activate">
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div>

          <div class="e-game inactivate">
            <div class="e-game__logo">
              <img src="../../assets/images/slot-logo/live22.png" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>LIVE22</h2>
                <span>ยังไม่ได้สมัคร</span>
              </div>
              <div class="e-game__info--btn">
                <div class="e-btn--activate">
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div>

          <div class="e-game inactivate">
            <div class="e-game__logo">
              <img src="../../assets/images/slot-logo/spadegaming.png" alt="" />
            </div>
            <div class="e-game__info">
              <div class="e-game__info--text">
                <h2>SPADEGAMING</h2>
                <span>ยังไม่ได้สมัคร</span>
              </div>
              <div class="e-game__info--btn">
                <div class="e-btn--activate">
                  <i class="fal fa-plus"></i>
                  <span>เปิดบัญชี</span>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </section>
    </div>

    <div class="e-modal">
      <div class="e-modal__body">
        <div class="e-modal__title">
          <h1>ข้อมูลเกม {{ dataModal.key }}</h1>
        </div>

        <div class="e-modal__body--text">
          <span>ชื่อผู้ใช้งาน :</span>
          <strong>{{ dataModal.Username }}</strong>

          <div class="e-btn--copy">
            <i class="far fa-clone"></i>
            <span>คัดลอก</span>
          </div>
        </div>
        <div class="e-modal__body--text">
          <span>รหัสผ่าน :</span>
          <strong>{{ dataModal.Password }}</strong>

          <div class="e-btn--copy">
            <i class="far fa-clone"></i>
            <span>คัดลอก</span>
          </div>
        </div>
        <div class="e-modal__download">
          <a href="#" class="e-btn--download">
            <i class="fab fa-android"></i>
            <div>
              <small>เล่นผ่าน</small>
              <h3>Android</h3>
            </div>
          </a>
          <a href="#" class="e-btn--download">
            <i class="fab fa-apple"></i>
            <div>
              <small>เล่นผ่าน</small>
              <h3>iOS</h3>
            </div>
          </a>
          <a href="#" class="e-btn--download">
            <i class="fab fa-windows"></i>
            <div>
              <small>เล่นผ่าน</small>
              <h3>Desktop</h3>
            </div>
          </a>
        </div>
        <a href="#" class="e-btn--primary">เข้าเกม</a>
        <div class="e-btn--secondary" v-on:click="toggleModal()">ยกเลิก</div>
      </div>
    </div>

    <Footer />

    <vue-snotify class="snotify-custom"></vue-snotify>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import { mapGetters } from "vuex";
// import { mapState } from "vuex";
export default {
  name: "Dashboard",
  components: {
    Header,
    Notice,
    Footer,
  },
  data() {
    return {
      prefix: "",
      listGames: [],
      dataModal: {},
      listLogo: {
        Gclub: require("../../assets/images/slot-logo/ambpoker.png"),
        Live22: require("../../assets/images/slot-logo/live22.png"),
        Ambpoker: require("../../assets/images/slot-logo/ambpoker.png"),
        Dragoonsoft: require("../../assets/images/slot-logo/dragoonsoft.png"),
        Pgslot: require("../../assets/images/slot-logo/pgslot.png"),
        Gamatron: require("../../assets/images/slot-logo/gamatron.png"),
        Slotxo: require("../../assets/images/slot-logo/slotxo.png"),
        Ameba: require("../../assets/images/slot-logo/ameba.png"),
        Spadegaming: require("../../assets/images/slot-logo/spadegaming.png"),
      },
    };
  },
  methods: {
    toggleModal(game = null) {
      if (game) {
        this.dataModal = {
          ...game,
          ...this.getProfile.game[game.key],
        };
        console.log("dataModal", this.dataModal);
      } else {
        this.dataModal = {};
      }

      // console.log("getProfile", this.dataModal);
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
    async createGame(provider, index) {
      let token = localStorage.getItem("token");
      if (!token) {
        await localStorage.removeItem("token");
        return false;
      }
      let optionts = {
        headers: { prefix: this.prefix, token: token },
        form: { provider: provider },
      };
      await this.$store.dispatch("Games/create", optionts);
      if (this.$store.state.Games.statusCode === 200) {
        const respCreate = await this.$store.state.Games.respCreate;
        this.notifySuccess(respCreate.message);
        await this.$store.dispatch("Members/me", optionts);
        this.listGames[index].balance = "฿ 0.00";
        this.listGames.splice(index, 1, this.listGames[index]);
        return true;
      }
      this.notifyError(this.$store.state.Games.responseError.message);
      return false;
    },
    async getBalance(provider, key = "") {
      let token = localStorage.getItem("token");
      if (!token) {
        await localStorage.removeItem("token");
        return 0;
      }
      let optionts = {
        headers: { prefix: this.prefix, token: token },
        form: { provider: provider },
      };
      await this.$store.dispatch("Games/balance", optionts);
      if (this.$store.state.Games.statusCode === 200) {
        const respBalance = await this.$store.state.Games.respBalance;
        let element = this.getPrefix.games[provider];
        element["key"] = key;
        element["balance"] =
          "฿ " + this.$coreFunc.numberFormat(respBalance.balance);
        this.listGames.push(element);
        return respBalance.balance;
      } else {
        if (!Object.hasOwnProperty.call(this.getPrefix.games, key)) {
          return 0;
        }
        let element = this.getPrefix.games[provider];
        element["key"] = key;
        element["balance"] = "ยังไม่ได้สมัคร";
        this.listGames.push(element);
      }
      return 0;
    },
    notifySuccess(msg = "") {
      // add dark black ground
      document.body.classList.add("dark-overlay");
      // Success case
      this.$snotify
        .success("สำเร็จ", msg, {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: false,
          buttons: [
            {
              text: "ยืนยัน",
              action: null,
            },
          ],
        })
        .on("destroyed", () => {
          document.body.classList.remove("dark-overlay");
        });
    },
    notifyError(msg = "") {
      // add dark black ground
      document.body.classList.add("dark-overlay");
      // Failed case
      this.$snotify
        .error("ไม่สำเร็จ", msg, {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: false,
          buttons: [
            {
              text: "ปิดหน้าต่าง",
              action: null,
            },
          ],
        })
        .on("destroyed", () => {
          document.body.classList.remove("dark-overlay");
        });
    },
    async init() {
      this.listGames = [];
      for (const key in this.getPrefix.games) {
        // console.log('test',this.getPrefix.games,key);
        if (Object.hasOwnProperty.call(this.getPrefix.games, key)) {
          const element = this.getPrefix.games[key];
          await this.getBalance(element.provider, key);
        }
      }
    },
  },
  computed: {
    // ...mapState('Members', ['respMe']),
    ...mapGetters("Prefix", ["getPrefix"]),
    ...mapGetters("Members", ["getProfile"]),
    // ...mapState({ respMe: "Members/respMe" }),
  },
  async mounted() {
    console.log("dashboard page");
    this.prefix = await localStorage.getItem("x-prefix");

    await this.init();
  },
};
</script>
