<template>
  <div>
    <section class="e-nav">
      <router-link to="/menus/dashboard">
        <i class="far fa-home"></i>
        <span>หน้าแรก</span>
      </router-link>
      <router-link to="/menus/deposit">
        <i class="far fa-arrow-to-bottom"></i>
        <span>ฝากเงิน</span>
      </router-link>
      <router-link to="/menus/withdraw">
        <i class="far fa-arrow-from-bottom"></i>
        <span>ถอนเงิน</span>
      </router-link>
      <router-link to="/menus/transfer">
        <i class="far fa-exchange"></i>
        <span>โยกเงิน</span>
      </router-link>
      <router-link to="/menus/account">
        <i class="far fa-user"></i>
        <span>บัญชี</span>
      </router-link>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Tabs",
  components: {
    // HelloWorld,
  },
};
</script>
