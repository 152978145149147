<template>
  <div>
    <div class="container">
      <div class="full-height">
        <router-view v-if="isActivePrefix" :dataPrefix="respVerify" />
        <div v-else>
          <div class="center-container-noprefix">
            <i class="fad fa-engine-warning fa-3x"></i>
            <h3 class="error-h3">เว็บไซต์ดังกล่าวไม่สามารถใช้งานได้</h3>
            <h5 class="erorr-h5">กรุณาตรวจสอบข้อมูลอีกครั้ง</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isActivePrefix: true,
      respVerify: {},
      prefix: "",
    };
  },
  methods: {
    async checkActivePrefix() {
      // console.log(this.$route.query);
      let optionts = {
        headers: { prefix: this.prefix },
      };
      await this.$store.dispatch("Prefix/verify", optionts);
      // console.log(this.respVerify.prefix);
      // await localStorage.setItem("info-prefix", JSON.stringify(this.respVerify));
      if (this.$store.state.Prefix.statusCode === 200) {
        this.respVerify = await this.$store.state.Prefix.respVerify.data;
        // document.title = this.respVerify.title;
        localStorage.setItem("title", this.respVerify.title);

        return true;
      }
      return false;
    },
  },
  async mounted() {
    console.log("app page");

    // console.log("mounted", this.$route);

    // setTimeout(async () => {
    // this.$nextTick(async () => {
    // console.log("mounted", this.$route.query);
    if (this.$route.query.prefix === "NO-PREFIX") {
      this.prefix = "";
      // console.log("no-prefix", this.$route.query.prefix);
      localStorage.removeItem("x-prefix");
      localStorage.removeItem("title");

      // localStorage.removeItem("info-prefix");
      this.isActivePrefix = false;
    } else {
      this.prefix = this.$route.query.prefix
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "");
      var url = new URL(window.location);
      url.searchParams.set("prefix", this.prefix);
      window.history.pushState({}, "", url);

      // console.log("has-prefix", this.$route.query.prefix);
      localStorage.setItem("x-prefix", this.prefix);
      this.isActivePrefix = await this.checkActivePrefix();
      //console.log(this.isActivePrefix)
    }
    // });
    // }, 1000);
  },
};
</script>

<style lang="css">
@import "./assets/css/bootstrap-grid.min.css";
@import "./assets/css/all.min.css";
@import "./assets/css/main.css";

.center-container-noprefix {
  display: flex;
  width: 100%;
  height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>