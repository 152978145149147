<template>
  <div>
    <Header :headerClass="true" menuName="โปรโมชั่น" />
    <Notice />

    <div class="container">
      <section class="e-breadcrumb">
        <ul class="e-breadcrumb__body">
          <li><router-link to="/menus/dashboard">หน้าแรก</router-link></li>
          <li>โปรโมชั่น</li>
        </ul>
      </section>

      <section class="e-wrapper">
        <h1 class="e-wrapper__title">โปรโมชั่น</h1>

        <div class="e-promotions">
          <div class="e-promo">
            <div class="e-promo__thumbnail">
              <img src="../../assets/images/promo/promo-1.jpg" alt="" />
            </div>
            <div class="e-promo__content">
              <h1>BONUS 100% ฝาก 50 รับ 100!! <br />(เฉพาะสมาชิกใหม่)</h1>
              <p>ฝาก 50 รับ 50 ทำยอด 300 ถอนได้ 100</p>
            </div>
            <div class="e-promo__btn">
              <label class="radio-group">
                <input type="radio" name="promo" value="" checked />
                <span class="text">รับโบนัสอัตโนมัติ</span>
                <span class="checkmark"></span>
              </label>
              <div class="e-btn--primary">รับโบนัส</div>
            </div>
          </div>

          <div class="e-promo">
            <div class="e-promo__thumbnail">
              <img src="../../assets/images/promo/promo-1.jpg" alt="" />
            </div>
            <div class="e-promo__content">
              <h1>สมัครสมาชิกใหม่ 50%</h1>
              <p>ยอดฝาก 100 บาท ขึ้นไปรับทันที 50%</p>
            </div>
            <div class="e-promo__btn">
              <label class="radio-group">
                <input type="radio" name="promo" value="" />
                <span class="text">รับโบนัสอัตโนมัติ</span>
                <span class="checkmark"></span>
              </label>
              <div class="e-btn--primary">รับโบนัส</div>
            </div>
          </div>

          <div class="e-promo">
            <div class="e-promo__thumbnail">
              <img src="../../assets/images/promo/promo-1.jpg" alt="" />
            </div>
            <div class="e-promo__content">
              <h1>BONUS 100% ฝาก 50 รับ 100!! <br />(เฉพาะสมาชิกใหม่)</h1>
              <p>ฝาก 50 รับ 50 ทำยอด 300 ถอนได้ 100</p>
            </div>
            <div class="e-promo__btn">
              <label class="radio-group">
                <input type="radio" name="promo" value="" />
                <span class="text">รับโบนัสอัตโนมัติ</span>
                <span class="checkmark"></span>
              </label>
              <div class="e-btn--primary">รับโบนัส</div>
            </div>
          </div>

          <div class="e-promo">
            <div class="e-promo__thumbnail">
              <img src="../../assets/images/promo/promo-1.jpg" alt="" />
            </div>
            <div class="e-promo__content">
              <h1>สมัครสมาชิกใหม่ 50%</h1>
              <p>ยอดฝาก 100 บาท ขึ้นไปรับทันที 50%</p>
            </div>
            <div class="e-promo__btn">
              <label class="radio-group">
                <input type="radio" name="promo" value="" />
                <span class="text">รับโบนัสอัตโนมัติ</span>
                <span class="checkmark"></span>
              </label>
              <div class="e-btn--primary">รับโบนัส</div>
            </div>
          </div>

          <div class="e-promo">
            <div class="e-promo__thumbnail">
              <img src="../../assets/images/promo/promo-1.jpg" alt="" />
            </div>
            <div class="e-promo__content">
              <h1>BONUS 100% ฝาก 50 รับ 100!! <br />(เฉพาะสมาชิกใหม่)</h1>
              <p>ฝาก 50 รับ 50 ทำยอด 300 ถอนได้ 100</p>
            </div>
            <div class="e-promo__btn">
              <label class="radio-group">
                <input type="radio" name="promo" value="" />
                <span class="text">รับโบนัสอัตโนมัติ</span>
                <span class="checkmark"></span>
              </label>
              <div class="e-btn--primary">รับโบนัส</div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
    <Tabs />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/header.vue";
import Notice from "@/components/layout/notice.vue";
import Footer from "@/components/layout/footer.vue";
import Tabs from "@/components/layout/tabs.vue";

export default {
  name: "Promotion",
  components: {
    Header,
    Notice,
    Footer,
    Tabs,
  },
  methods: {
    toggleModal() {
      const element = document.getElementsByClassName("e-modal")[0];
      element.classList.toggle("open");
    },
  },
};
</script>
